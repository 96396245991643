import React from 'react'

import Fade from 'react-reveal/Fade';

const Services = ({ title, items }) => (
  <section className='services'>
    <div className='services__inner'>
      <Fade distance='40px' bottom>
        <h5>{ title }</h5>
      </Fade>

      <div className='services__list'>
        { items &&
          <Fade distance='40px' bottom>
            { items.map((el, i) => (
              <div className='services__item' key={i}>
                <h3>{ el.title }</h3>
                <div dangerouslySetInnerHTML={{__html: el.content }} />
              </div>
            )) }
          </Fade>
        }
      </div>
    </div>
  </section>
)

export default Services
