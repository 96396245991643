import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Fade from 'react-reveal/Fade'
import Link from '../utils/link'

class Intro extends Component {

  state = {
    opacity: 1
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let el = this.refs.intro
    let opacity = el.getBoundingClientRect().y > el.clientHeight * -1 ? 1 : 0
    this.setState({ opacity })
  }


  render() {
    const { name, subtitle, copy, picture } = this.props
    const { options } = this.props.data.wordpressAcfOptions
    let { opacity } = this.state

    return (
      <section className='intro' ref='intro'>
        <div className='intro__inner'>
          <div className='intro__header'>
            <div className='intro__sticky' style={{opacity}}>
              <Fade distance='40px' bottom>
                <h1>{ name }</h1>
                <h2 dangerouslySetInnerHTML={{__html: subtitle}} />
                <ul>
                  { options.email_address && <li><Link to={`mailto:${options.email_address}`}>Email Matthew</Link></li> }
                  { options.phone_number && <li><Link to={`tel:${options.phone_number}`}>{options.phone_number}</Link></li> }
                  { options.linkedin && <li><Link to={options.linkedin}>LinkedIn</Link></li> }
                </ul>
              </Fade>
            </div>
          </div>
          <div className='intro__content'>
            { picture &&
              <Img fluid={picture.localFile.childImageSharp.fluid} alt={name} />
            }
            <Fade distance='40px' bottom>
              <h3 dangerouslySetInnerHTML={{__html: copy.lead_text }} />
              <h5 dangerouslySetInnerHTML={{__html: copy.secondary_text }} />
              <div dangerouslySetInnerHTML={{__html: copy.body_text }} />
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

const IntroExport = (props) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            email_address
            linkedin
            phone_number
          }
        }
      }
    `}
    render={data => {
      return (<Intro data={data} {...props} />)
    }
    }
  />
)

export default IntroExport
