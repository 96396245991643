import React from 'react'
import Img from 'gatsby-image'

import Fade from 'react-reveal/Fade';

const Career = ({ title, content, picture }) => (
  <section className='career'>
    <div className='career__inner'>
      <div className='career__text'>
        <Fade distance='40px' bottom>
          <h3>{ title }</h3>
          <div dangerouslySetInnerHTML={{__html: content }} />
        </Fade>
      </div>
    </div>
    <div className='career__image'>
      { picture &&
        <Fade>
          <Img fluid={picture.localFile.childImageSharp.fluid} alt={title} />
        </Fade>
      }
    </div>
  </section>
)

export default Career
