import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Link from '../utils/link'

class Header extends Component {

	isScrolling = false

	state = {
    offcanvas: false
	}

  _toggleOffCanvas = () => {
    this.setState({ offcanvas: !this.state.offcanvas })
  }

  _hideOffCanvas = () => {
    this.setState({ offcanvas: false })
  }

  renderMenu() {
    const { menu } = this.props.data

    let props = {
      onClick: this._hideOffCanvas,
    }

    return (
      <ul>
        { menu && menu.items.map((el, i) => (
          <li key={i}><Link to={`/${el.slug}`} {...props}>{ el.title }</Link></li>
        )) }
      </ul>
    )
  }

	render() {
    let { offcanvas } = this.state

		return (
			<>
				<header className={`header header--sticky`}>
					<div className='header__inner'>
						<div className='header__logo'>
							<Link to='/'><img src={require('../assets/images/logo.svg')} alt='Matthew Pringle' /></Link>
						</div>
						<nav className='header__nav'>
              { this.renderMenu() }
							<button className={'header__hamburger'} onClick={this._toggleOffCanvas}>
								<span className='lines'></span>
							</button>
						</nav>
					</div>
				</header>

				<div className={`off-canvas ${offcanvas ? 'active' : ''}`}>
          <button className={'header__hamburger'} onClick={this._toggleOffCanvas}>
            <span className='lines'></span>
          </button>

					<nav className='off-canvas__nav'>
            { this.renderMenu() }
					</nav>

				</div>

			</>
		)

	}

}


const HeaderExport = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query {
        menu: wordpressWpApiMenusMenusItems(slug:{eq: "header"}) {
          id
          items {
            order
            title
            posttype: object
            slug: object_slug
          }
        }
      }
    `}
    render={data => {
      return (<Header location={location} data={data} />)
    }
    }
  />
)

export default HeaderExport
