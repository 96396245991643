import React from 'react'

import Fade from 'react-reveal/Fade';

const Thirds = ({ title, subtitle, sections }) => (
  <section className='thirds'>
    { (title || subtitle) &&
      <div className='thirds__intro'>
        { title && <h3 dangerouslySetInnerHTML={{ __html: title }} /> }
        { subtitle && <h5 dangerouslySetInnerHTML={{ __html: subtitle }} /> }
      </div>
    }
    <div className='thirds__header'>
      { sections.map((el, i) => <h4 key={i}>{ el.title }</h4>) }
    </div>
    <div className='thirds__inner'>
      <Fade distance='40px' bottom>
        { sections.map((el, i) => (
          <div key={i}>
            <h4>{ el.title }</h4>
            <ul>
              { el.text.split('\r\n').map((t, j) => <li key={j}><span>{ t }</span></li>) }
            </ul>
          </div>
        )) }
      </Fade>
    </div>
  </section>
)

export default Thirds
