import React from 'react'

import Fade from 'react-reveal/Fade';

const Approach = ({ title, subtitle, items }) => {

  let num = Math.ceil(items.length / 3)
  let els = items.map((el, i) => (
    <div className='approach__item' key={i}>
      <h4>{ el.title }</h4>
      <div dangerouslySetInnerHTML={{__html: el.content }} />
    </div>
  ))

  return (
    <section className='approach'>
      <div className='approach__inner'>
        <Fade distance='40px' bottom>
          <h3>{ title }</h3>
          <h4 dangerouslySetInnerHTML={{__html: subtitle }} />
        </Fade>
        <div className='approach__list'>
          <Fade distance='40px' bottom>
            <div>
              { els.map((el, i) => i < num && el) }
            </div>
            <div>
              { els.map((el, i) => i >= num && i < num * 2 && el) }
            </div>
            <div>
              { els.map((el, i) => i >= num * 2 && i < num * 3 && el) }
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}

export default Approach
