import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Link from '../utils/link'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Contact extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    best_time: '',
    message: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  renderForm() {

    let props = {
      ref: 'form',
      name: 'contact',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    return (
      <form {...props}>
        <div className='form__row'>
          <input type='text' name='name' placeholder='Your name' onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <input type='email' name='email' placeholder='Email' onChange={this.handleChange} required  />
        </div>
        <div className='form__row form__row--double'>
          <input type='text' name='phone' placeholder='Contact Number' onChange={this.handleChange} />
          <input type='text' name='best_time' placeholder='Best Time' onChange={this.handleChange} />
        </div>
        <div className='form__row'>
          <textarea name='message' placeholder='Message' onChange={this.handleChange} />
        </div>
        <div className='form__row form__row--submit'>
          <button type='submit'>Send</button>
        </div>
      </form>
    )
  }

  render() {
    const { options } = this.props.data.wordpressAcfOptions

    const completeMessage = (<p>Thanks for getting in contact.<br />  I will be in touch soon.</p>)

    return (
      <section className='contact'>
        <div className='contact__inner'>
          <Fade distance='40px' bottom>

            <div className='contact__form'>
              <h3>Get in touch</h3>
              <div dangerouslySetInnerHTML={{__html: this.props.text_content }} />
              { this.state.result === 'success' ?
                completeMessage :
                this.renderForm()
              }
            </div>
            <div className='contact__content'>
              <h5>Contact Details</h5>
              <ul>
                { options.email_address && <li><Link to={`mailto:${options.email_address}`}>Email Matthew</Link></li> }
                { options.phone_number && <li><Link to={`tel:${options.phone_number}`}>{options.phone_number}</Link></li> }
                { options.resume && <li><Link to={options.resume.source_url}>Resumé</Link></li> }
                { options.linkedin && <li><Link to={options.linkedin}>LinkedIn</Link></li> }
              </ul>
            </div>
          </Fade>
        </div>
      </section>
    )
  }
}

const ContactExport = (props) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            email_address
            linkedin
            phone_number
            resume {
              source_url
            }
          }
        }
      }
    `}
    render={data => {
      return (<Contact data={data} {...props} />)
    }
    }
  />
)

export default ContactExport
