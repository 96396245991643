import React from 'react'

import Header from '../components/header'
import Content from '../components/content'
import Contact from '../components/contact'
import Career from '../components/career'
import Approach from '../components/approach'
import Intro from '../components/intro'
import Services from '../components/services'
import Thirds from '../components/thirds'

const renderBlock = (param, el, i, page={}) => {
  let block = {
    'WordPressAcf_header' : (el, i) => <Header key={i} {...el} />,
    'WordPressAcf_content' : (el, i) => <Content key={i} {...el} />,
    'WordPressAcf_contact' : (el, i) => <Contact key={i} {...el} />,
    'WordPressAcf_career' : (el, i) => <Career key={i} {...el} />,
    'WordPressAcf_approach' : (el, i) => <Approach key={i} {...el} />,
    'WordPressAcf_intro' : (el, i) => <Intro key={i} {...el} />,
    'WordPressAcf_services' : (el, i) => <Services key={i} {...el} />,
    'WordPressAcf_thirds' : (el, i) => <Thirds key={i} {...el} />,
  }[param]

  if (!block) return

  return block(el, i)
}

export default renderBlock
