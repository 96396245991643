import React from 'react'
import Img from 'gatsby-image'

import Fade from 'react-reveal/Fade';

const Content = ({ title, body_copy, picture }) => (
  <section className='content'>
    <div className='content__inner'>
      <div className='content__image'>
        { picture &&
          <Fade>
            <Img fluid={picture.localFile.childImageSharp.fluid} alt={title} />
          </Fade>
        }
      </div>
      <div className='content__text'>
        <Fade distance='40px' bottom>
          <h4>{ title }</h4>
          <div dangerouslySetInnerHTML={{__html: body_copy}} />
        </Fade>
      </div>
    </div>
  </section>
)

export default Content
