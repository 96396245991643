import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {
  render() {
    let page = this.props.data.wordpressPage
    return (
      <>
        <SEO title={he.decode(page.title)} />
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
      </>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPage(id: {eq: $id}) {
      title
      slug
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_intro {
            name
            subtitle
            copy {
              lead_text
              secondary_text
              body_text
            }
            picture {
              id
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WordPressAcf_content {
            title
            body_copy
            picture {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WordPressAcf_contact {
            text_content
          }
          ... on WordPressAcf_thirds {
            title
            subtitle
            sections {
              title
              text
            }
          }
          ... on WordPressAcf_approach {
            title
            subtitle
            items {
              title
              content
            }
          }
          ... on WordPressAcf_career {
            title
            content
            picture {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WordPressAcf_services {
            title
            items {
              title
              content
            }
          }
        }
      }
    }
  }
`


